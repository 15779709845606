@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;500;600;700;800&display=swap");

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-size: 16px;
  background-color: #fbfcff;
  font-family: "Sora", sans-serif;
  overflow-x: hidden;
}
li {
  list-style-type: none;
}
a {
  color: #000;
  text-decoration: none;
}

.App {
  padding: 1.5rem 8.4375rem;
}
/* Header Section*/
.header {
  display: flex;
  justify-content: space-between;
}
nav {
  display: flex;
  align-items: center;
}
.nav-items {
  display: flex;
  align-items: center;
}
.hamburger-icon {
  display: none;
}
.nav-links {
  display: flex;
  align-items: center;
}
.nav-logo {
  margin-right: 3.125rem;
}
.nav-item {
  margin-right: 1.375rem;
}
.nav-item:hover {
  border-bottom: 2px solid #000;
}
.menu-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fbfcff;
  height: 0;
  opacity: 0;
  box-shadow: 0px 5px 10px 0px #aaa;
}
.left_side {
  display: flex;
}
.profile {
  display: flex;
  align-items: center;
}
.profile_text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 1rem;
}
.name {
  font-weight: bold;
}

.user_img {
  margin-right: 1rem;
}
.cart-btn {
  cursor: pointer;
}
.user_img,
.cart_img {
  width: 2.25rem;
  height: 2.25rem;
}

/* Hero Section */
.hero {
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 100px;
}
.hero_title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1.25rem;
}
.hero_p {
  font-size: 1.3rem;
  margin-bottom: 2.5rem;
}
.hero_img {
  width: 40.125rem;
  height: 37.75rem;
}
.btn {
  padding: 0.75rem 1.5rem;
  background-color: #212121;
  color: #fff;
  text-align: center;
  align-items: center;
  transition: all 0.5s linear;
  cursor: pointer;
}
.btn:hover {
  color: #212121;
  background-color: transparent;
}

/*Promotions Section  */
.promotions {
  margin-top: 6.25rem;
}
.promotions-title-blue {
  text-align: center;
  color: #0062f5;
  font-weight: 400;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
}
.promotions-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2.8125rem;
}
.promotions-events {
  display: flex;
  gap: 0.625rem;
  justify-content: center;
}
.promotions-left {
  width: 50%;
}
.promotions-right {
  width: 50%;
}

.gray {
  background-color: #d6d6d8;
  margin-bottom: 0.875rem;
  height: 13.625rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gray-text {
  margin: 0 auto;
}
.gray-title {
  font-size: 1.75rem;
  font-weight: bold;
}
.gray-title > span {
  font-size: 2rem;
  font-weight: 900;
}
.gray-info {
  font-size: 1.125rem;
}
.black {
  background-color: #212121;
  height: 13.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.black-title {
  font-size: 2.25rem;
  font-weight: 900;
  margin-bottom: 0.75rem;
}
.black-info {
  font-size: 0.875rem;
}
.black-code {
  margin-top: 0.25rem;
  padding: 0.625rem 2.875rem;
  background-color: #424242;
  border-radius: 0.5rem;
  font-size: 1.0625rem;
  font-weight: 400;
  letter-spacing: 1.5;
}

.promotions-right {
  display: flex;
  height: 28.125rem;
}
.pink {
  background-color: #efe1c7;
  margin-right: 0.875rem;
  padding: 1rem 1rem 0 1rem;
}
.pink-title {
  color: #212121;
  font-size: 15px;
  margin-bottom: 0.5rem;
}
.old-price {
  text-decoration: line-through;
  font-size: 1rem;
  color: #666666;
  margin-right: 0.875rem;
}
.new-price {
  font-size: 1.125rem;
  font-weight: 700;
}
.pinkImg {
  margin-top: 6px;
  overflow: hidden;
}
.green {
  background-color: #d6d6d9;
  padding: 1rem 1rem 0 1rem;
}
.green-title {
  font-size: 15px;
  margin-bottom: 0.5rem;
}
.pink-img {
  position: relative;
}
.green-img {
  position: relative;
}
.greenImg {
  margin-top: 6px;
  overflow: hidden;
}
.btn-arrow {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 1.25rem;
  right: 0.5rem;
  cursor: pointer;
}

/* Product Slider Section */
.products {
  margin-top: 6.25rem;
  margin-bottom: 6.25rem;
}
.products-title-blue {
  text-align: center;
  color: #0062f5;
  font-weight: 400;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
}

.products-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2.8125rem;
}
.swiper-slide .product-container {
  max-height: 31.25rem;
}
.product-img {
  width: 23.125rem;
  height: 27.625rem;
  object-fit: cover;
  overflow: hidden;
  margin-bottom: 1rem;
}
.product-name {
  font-size: 1rem;
  font-weight: bold;
}
.product-price {
  font-size: 0.8rem;
  font-weight: 700;
}

/* Product Overview */
.productOverview {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 12.5rem;
}
.overview-title {
  font-size: 2.75rem;
  font-weight: bold;
  display: flex;
  width: 40%;
  margin-bottom: 2rem;
}
.overview-content {
  display: flex;
}
.overview-info {
  position: relative;
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}
.overview-info::after {
  content: " Different From Others";

  position: absolute;
  line-height: 1;
  z-index: -1;
  opacity: 5%;
  font-size: 100px;
  font-weight: 900;
  margin-bottom: 3.5rem;
}
.info {
  width: 40%;
  margin-bottom: 2.5rem;
  margin-right: 2.5rem;
}
.info-title {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  margin-bottom: 0.5rem;
  word-break: break;
}
.info-text {
  font-size: 0.875rem;
  letter-spacing: 0.1rem;
  line-height: 1.5;
}
.overview-image {
  width: 30%;
  text-align: center;
}
.overview-img {
  width: 20, 3125rem;
  height: 25rem;
}
.overview-image-info {
  margin: auto 0;
  width: 30%;
  padding-left: 2.5rem;
}
.image-info-text {
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
  margin-bottom: 3.125rem;
}
/* Email Subscription */
.email-subs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 6.25rem;
}
.email-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
}
.email-info {
  font-size: 0.875rem;
  font-weight: light;
  margin-bottom: 2.5rem;
}
.email-input {
  width: 20rem;
  height: 2.5rem;
  margin-right: 0.5rem;
  border: 1.2px solid #f1f1f1;
  padding: 0.75rem 1.25rem;
  font-size: 0.8125rem;
  font-weight: regular;
  color: #666666;
}
.email-btn {
  padding: 0.625rem 1.5rem;
  cursor: pointer;
}
/* Footer */

.footer-content {
  padding: 3.125rem 0;
  width: 100%;
  display: flex;
}
.footer-info {
  width: 40%;
  margin-right: 9.75rem;
}
.footer-logo {
  margin-bottom: 1.125rem;
}
.footer-text {
  font-size: 0.75rem;
  font-weight: 400;
  color: #666666;
  margin-bottom: 2.5rem;
}
.btn-social {
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  background-color: #f1f1f1;
  color: #666666;
  margin-right: 10px;
  border-radius: 0.375rem;
  cursor: pointer;
}
.btn-social > svg {
  position: relative;
  top: 0.3125rem;
}
.footer-company {
  width: 20%;
  margin-right: 9.75rem;
}
.footer-title {
  font-size: 0.9375rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
  color: #666666;
}
.footer-link {
  margin-bottom: 0.625rem;
}
.footer-link > a {
  font-weight: 400;
  color: #666666;
}
.footer-support {
  width: 20%;
  margin-right: 9.75rem;
}
.footer-contact {
  width: 20%;
}
.line {
  display: block;
  margin-left: -8.4375rem;
  width: 121.6%;
}
.bottom-content {
  display: flex;
  justify-content: space-between;
  margin-top: 3.125rem;
}
.copyright {
  font-size: 0.75rem;
  color: #666666;
  font-weight: 400;
}
.createBy {
  font-size: 0.75rem;
  color: #666666;
  font-weight: 400;
}
/* Products */
.products-content {
  display: flex;
}
.products-list {
  width: 100%;
  padding: 0 0 0 2.75rem;
}
.product-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
}
.product-container {
  max-width: 17.375rem;
  max-height: 24.0625rem;
}

.product-list .product-img {
  width: 100%;
  max-height: 18.5rem;
  object-fit: cover;
  object-position: top center;
}
.product-list .product-name {
  font-size: 0.9375rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.products-content {
  margin-bottom: 6.25rem;
}
.item-info {
  display: flex;
  flex-direction: column;
}
/* Products Filter */
.filter-header {
  display: flex;
  justify-content: space-between;
}
.prev {
  margin-bottom: 2rem;
  font-size: 0.75rem;
  letter-spacing: 0.5px;
  color: #666666;
  text-transform: capitalize;
  font-weight: bold;
}
.prev a:first-child {
  font-weight: 400;
}
.products-filter {
  max-width: 15.75rem;
}
.filter-open-btn {
  visibility: hidden;
  padding: 0.375rem 1.25rem;
  min-height: 2.25rem;
  outline: rgb(204, 204, 204) solid 0.09375rem;
  border-radius: 1.875rem;
  cursor: pointer;
}
.filter-title {
  font-size: 0.8125rem;
  font-weight: 800;
  color: #666666;
  letter-spacing: 0.5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.filter-title svg {
  width: 0.875rem;
  height: 0.875rem;
}
/* Category Options */
.category-options {
  height: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: flex-start;
  transition: all 1s ease;
}
.category-btn {
  width: 100%;
  padding: 0.75rem 1.5rem;
  transition: all 0.5s linear;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: space-between;
  color: #212121;
  background-color: transparent;
  cursor: pointer;
}
.active-btn {
  background-color: #212121;
  color: #fff;
  cursor: pointer;
}
.closeCategory {
  height: 26rem;
  margin-bottom: 2rem;
}

/* Price Options */

.price-options {
  display: flex;
  gap: 1rem;
  height: 2rem;
  margin-bottom: 2rem;
  visibility: visible;
  transition: all 1s ease;
}
.price-btn {
  padding: 0.4375rem 1.65rem;
  border: 1px solid #c4c4c4;
  font-size: 0.875rem;
  border-radius: 1rem;
  cursor: pointer;
  transition: all 0.4s ease;
}
.activePrice {
  background-color: #000;
  color: #fff;
}
.closePrice {
  height: 0px;
  opacity: 0;
  margin-bottom: 0;
}
/* Condition Filter */
.condition-options {
  display: flex;
  flex-direction: column;
  height: 3rem;
  visibility: visible;
  transition: all 1s ease;
  margin-bottom: 2rem;
}
.option {
  display: flex;
  align-content: center;
}
.condition-option {
  margin-right: 0.75rem;
  width: 1.125rem;
  height: 1.125rem;
  cursor: pointer;
  margin-bottom: 1.25rem;
  transition: all 0.2s linear;
}
.condition-option:checked {
  accent-color: #212121;
}
.option label {
  font-size: 0.875rem;
  font-weight: 500;
}
.closeCondition {
  height: 0px;
  opacity: 0;
  margin-bottom: 0;
}

/* Rate Filter */
.rate-options {
  display: flex;
  flex-direction: column;
  height: 100px;
  visibility: visible;
  transition: all 1s ease;
  margin-bottom: 2rem;
}
.rate-option {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.25rem;
}
.starCheckbox {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.75rem;
}
.starCheckbox:checked {
  accent-color: #212121;
}
.rate-option label {
  display: flex;
}
.stars {
  margin-right: 0.75rem;
}
.star-rate {
  font-size: 0.875rem;
  font-weight: 600;
}
.fill {
  color: #ffc700;
}
.empty {
  color: #fff;
  stroke: #ffc700;
  stroke-width: 50px;
}
.closeRate {
  height: 0px;
  opacity: 0;
  margin-bottom: 0;
}

/* Product Detail */
.product-detail-hero {
  margin-top: 2rem;
  display: flex;
}

.images-list {
  width: 12%;
  display: grid;
  height: 29.8125rem;
  overflow: scroll;
  overflow-x: hidden;
  margin-right: 5.5rem;
}
.product-small-img {
  width: 8.375rem;
  height: 8.9375rem;
  object-fit: cover;
  object-position: center top;
}
.product-image {
  width: 50%;
  max-height: 37.5rem;
  overflow: hidden;
  margin-right: 3.75rem;
}
.product-headerImg {
  width: 100%;
  object-fit: cover;
  object-position: center top;
}
.product-info {
  width: 25%;
  padding-top: 5.75rem;
}
.info-name {
  font-size: 1.4rem;
  font-weight: 600;
}
.sizes {
  margin-top: 5.625rem;
  margin-bottom: 2.5rem;
}
.sizes h3 {
  font-size: 0.8125rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
}
.size {
  cursor: pointer;
  text-align: center;
  font-size: 0.5rem 1rem;
  color: #666666;
  text-transform: uppercase;
  padding: 1rem;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
}
.active-size {
  background-color: #fff;
  color: #000;
  font-weight: bold;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 2px 5px 1px #f1f1f1, 0px -2px 5px 1px #f1f1f1;
}
.size:not(.size:last-child) {
  margin-right: 1rem;
}

.info-price {
  display: flex;
  align-items: center;
}
.btn-cart {
  display: flex;
  margin-right: 1.5rem;
  align-items: center;
  cursor: pointer;
}
.btn-cart > svg {
  margin-right: 0.5rem;
}
.price {
  font-size: 1.5rem;
  font-weight: bold;
}

/* Product Detail Information */
.product-information {
  padding: 3.125rem 4.625rem;
}
.information-title {
  position: relative;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 4rem;
}
.information-title::after {
  content: "Overview";
  z-index: -1;
  position: absolute;
  line-height: 1;
  opacity: 3%;
  font-size: 7.5rem;
  font-weight: 900;
  top: -40px;
  left: 0;
}
.product-information hr {
  border: 1px solid #ebebeb;
}
.information-content {
  padding-top: 3rem;
}
.details {
  display: flex;
}
.content-title {
  margin-right: 8.75rem;
  font-size: 1rem;
  font-weight: bold;
  color: #666666;
  white-space: nowrap;
}
.details-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 0.875rem;
  font-weight: 300;
  margin-bottom: 2.5rem;
}
.care {
  display: flex;
}
.care-info {
  display: flex;
  flex-direction: column;

  padding-left: 1.5rem;
  font-size: 0.875rem;
  font-weight: 600;
}

/* Product Detail Comments */
.detail-comments {
  padding: 3.125rem 4.625rem;
  position: relative;
}
.comments-title {
  position: relative;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 4rem;
}
.comments-title::after {
  content: "Reviews";
  z-index: -1;
  position: absolute;
  line-height: 1;
  opacity: 3%;
  font-size: 7.5rem;
  font-weight: 900;
  top: -40px;
  left: -0.5rem;
}
.detail-comments > .stars {
  position: absolute;
  top: 6.25rem;
  left: 4.625rem;
}
.detail-comments hr {
  border: 1px solid #ebebeb;
}
.comment {
  padding: 3rem 0;
  display: flex;
}
.profile-info {
  width: 16.875rem;
}
.person-name {
  white-space: nowrap;
  margin-right: 8.75rem;
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 1rem;
}
.stars {
  margin-bottom: 1rem;
}
.comment-info {
  padding-left: 1.5rem;
}
.comment-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.comment-text {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-bottom: 1.25rem;
}
.comments-images {
  display: flex;
  flex-direction: row;
}
.comment-image {
  width: 3.75rem;
  height: 3.75rem;
  object-fit: cover;
  object-position: center top;
  margin-right: 1rem;
  border-radius: 0.25rem;
}
.danger {
  color: #ff5733;
}
/* Cart Design */
.cart-page {
  margin-top: 2rem;
  padding: 1.1875rem 4.625rem;
}
.cart-title {
  padding: 3.75rem 0;
  font-size: 1.5rem;
  font-weight: bold;
}
.cart-page hr {
  border: 1px solid #ebebeb;
}
.cart-container {
  display: flex;
  justify-content: space-between;
  gap: 5.625rem;
}
.items-lists {
  display: flex;
  flex-direction: column;
  width: 37rem;
}
.cart-item {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  gap: 1.5rem;
}
.cart-img {
  width: 8.5625rem;
  height: 8.5625rem;
  object-fit: cover;
  object-position: center top;
  border-radius: 0.5rem;
}
.cart-info {
  width: 100%;
}
.info-up {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.cart-name {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
}
.delete-btn {
  font-size: 1.5rem;
  cursor: pointer;
}
.cartItem-info {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}
.delivery {
  margin-right: 5rem;
}
.delivery-title {
  font-size: 0.8125rem;
  font-weight: 800;
  margin-bottom: 0.2rem;
}
.delivery-time {
  font-size: 0.875rem;
  font-weight: 700;
  color: #ffc700;
}
.size-info {
  font-size: 0.875rem;
}
.size-info > span {
  font-weight: 900;
  text-transform: uppercase;
}
.info-down {
  display: flex;
  justify-content: space-between;
}
.price-info {
  font-size: 1rem;
  font-weight: 900;
}
.up-down {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}
.decrease,
.increase {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.decrease {
  background-color: #f1f1f1;
}
.item-length {
  font-size: 1rem;
  font-weight: 900;
}
.increase {
  border: 2px solid #000;
  background-color: transparent;
}
/* Cart Summary */
.summary {
  margin-top: 1.5rem;
  width: 21.25rem;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
}
.sum-title {
  font-size: 1rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
}
.items-price,
.delivery-price,
.total-price {
  display: flex;
  justify-content: space-between;
  margin: 1.25rem 0;
  font-size: 1rem;
}
.summary .num {
  font-weight: 800;
}
.summary .checkout-btn {
  margin-top: 1rem;
  display: block;
  cursor: pointer;
}
/* Empty Cart Component */
.empty-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
}
.empty-cart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.empty-img {
  max-width: 100%;
  display: block;
  margin-bottom: 1.75rem;
}
.empty-title {
  color: #01040d;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
}
.empty-info {
  color: #8991a4;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  margin-bottom: 0.625rem;
  text-align: center;
}
.empty-btn {
  width: 100%;
  cursor: pointer;
}
@media only screen and (max-width: 748px) {
  .App {
    padding: 0.625rem 1.5rem;
    overflow: hidden;
  }

  nav {
    flex-direction: row;
  }
  .btn {
    padding: 0.5rem 0.75rem;
  }
  .hamburger-icon {
    cursor: pointer;
    display: block;
    background-color: #f9fafc;
    padding: 0.5rem 0.625rem;
    border: 1px solid #f1f1f1;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  .nav-links {
    display: none;
  }
  .menu-items {
    position: absolute;
    top: 0;
    margin-top: 50px;
    margin-bottom: 2.5rem;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    transition: all 0.5s linear;
  }
  .open-items {
    height: 100px;
    opacity: 1;
  }
  .profile_text {
    display: none;
  }
  .user_img {
    display: none;
  }
  /* Hero Component */
  .hero {
    flex-direction: column;
    gap: 0;
  }
  .hero_text::after {
    content: " ";
    position: absolute;
    top: 5.5rem;
    left: 2.4rem;
    z-index: -1;
    width: 300px;
    height: 300px;
    background-color: #ffece3;
    border-radius: 50%;
  }
  .hero_title {
    font-size: 2.5rem;
  }
  .hero_img {
    display: none;
  }
  /* Promotions */
  .promotions {
    margin-top: 4rem;
  }
  .promotions-events {
    flex-direction: column;
  }
  .promotions-left {
    width: 100%;
  }
  .gray {
    padding: 0.5rem 1rem;
  }
  .gray-title {
    font-size: 1.5rem;
  }
  .gray-info {
    font-size: 1rem;
  }
  .black-title {
    font-size: 2rem;
  }
  .black-info {
    font-size: 0.7rem;
  }
  .black-code {
    padding: 0.5rem 2.5rem;
  }
  .promotions-right {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .pink {
    margin-right: 0;
    margin-bottom: 0.875rem;
  }
  /* Products Slide */
  .products {
    margin: 4rem 0;
  }

  /* Products Overview */
  .productOverview {
    margin-bottom: 4rem;
  }
  .overview-title {
    font-size: 1.5rem;
  }
  .overview-content {
    flex-wrap: wrap;
  }
  .overview-info {
    width: 100%;
  }
  .overview-info::after {
    margin-bottom: 0;
  }
  .info {
    margin: 0;
  }
  .overview-image {
    width: 100%;
  }
  .overview-image-info {
    margin-top: 1rem;
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
  .image-info-text {
    font-size: 0.6rem;
    line-height: 1rem;
    letter-spacing: 0;
    text-align: center;
  }
  /* Email Subs Component */
  .email-subs {
    margin-bottom: 4rem;
  }
  .email-title {
    text-align: center;
    font-size: 1.75rem;
  }
  .email-info {
    letter-spacing: 1px;
    text-align: center;
  }
  .email-input {
    width: 10rem;
    margin-right: 0.5rem;
    height: 2.5rem;
  }
  .email-btn {
    padding: 0.5rem;
  }
  /* Footer */
  .footer-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .footer-info {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .footer-company {
    width: auto;
    margin-right: 1rem;
  }
  .footer-support {
    width: auto;
    margin-right: 1rem;
  }
  .footer-contact {
    width: auto;
  }
  .footer-title {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
  .footer-link {
    margin-bottom: 0.4rem;
  }
  .footer-link > a {
    font-size: 0.8rem;
  }
  .line {
    margin-left: 0;
    width: auto;
  }
  .bottom-content {
    margin-top: 0.5rem;
  }
  /* Filter Section */
  .filter-header {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .filter-btn {
    transition: all 0.5s linear;
    cursor: pointer;
  }
  .filter-open-btn {
    display: flex;
    align-items: center;
    visibility: visible;
    gap: 8px;
    font-size: 0.875rem;
  }
  .category-side {
    height: 260vh;
    left: -550px;
    z-index: 1;
    position: absolute;
    background-color: #fbfcff;
    padding: 0 2rem;

    transition: all 1s linear;
  }
  .open-category {
    left: 0;
  }
  /* Products List */
  .products-list {
    padding: 0;
  }
  .product-list {
    grid-template-columns: repeat(2, 1fr);
  }
  .product-list .product-name {
    font-size: 0.8375rem;
    margin-bottom: 0.5rem;
  }
  /* Products Detail */
  .product-detail-hero {
    flex-direction: column;
    flex-direction: column-reverse;
  }
  .images-list {
    display: flex;
    overflow: scroll;
    overflow-y: hidden;
    width: 100%;
    height: auto;
  }
  .product-info {
    width: 100%;
    padding-top: 2rem;
    margin-bottom: 2rem;
  }
  .product-image {
    width: 100%;
    margin: 0 0 2rem;
  }
  .info-name {
    font-size: 1rem;
  }
  .sizes {
    margin: 1rem 0;
  }
  .sizes h3 {
    font-size: 0.7rem;
    margin-bottom: 1rem;
  }
  .size:not(.size:last-child) {
    margin-right: 0.5rem;
  }
  .size {
    padding: 0.5rem 0.75rem;
  }
  .info-price {
    justify-content: center;
  }
  .btn-cart {
    margin-right: 1rem;
  }
  .price {
    font-size: 1rem;
  }
  /* Detail Information */
  .product-information {
    padding: 2.25rem 0.5rem;
  }
  .information-title {
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }
  .information-title::after {
    top: -17px;
    font-size: 3.5rem;
  }
  .information-content {
    padding-top: 1.5rem;
  }
  .content-title {
    margin-right: 0;
    font-size: 1rem;
    font-weight: bold;
    color: #666666;
  }
  .details {
    gap: 0.5rem;
  }
  .details-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 0.75rem;
    font-weight: 300;
    margin-bottom: 1.5rem;
  }
  /* Detail Comments */
  .detail-comments {
    padding: 2.25rem 0.5rem;
  }
  .comments-title {
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }
  .comments-title::after {
    font-size: 3.5rem;
    top: -17px;
  }
  .detail-comments > .stars {
    position: absolute;
    top: 3.8rem;
    left: 0.35rem;
  }
  .comment {
    padding: 1rem 0;
    flex-direction: column;
    gap: 1rem;
  }
  .profile-info {
    width: auto;
  }
  .person-name {
    margin-right: 0;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  .stars {
    margin: 0;
  }
  .comment-date {
    font-size: 0.8rem;
  }
  .comment-info {
    padding-left: 0;
  }
  .comment-title {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
  .comment-text {
    font-size: 0.65rem;
    line-height: 1rem;
    margin-bottom: 1rem;
  }
  .cart-container {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (min-width: 748px) and (max-width: 1080px) {
  .App {
    padding: 1.5rem 2.75rem;
  }
  /* Header */
  .profile_text {
    align-items: flex-start;
  }
  /* Hero Component */
  .hero {
    justify-content: space-between;
    gap: 0rem;
  }
  .hero_title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  .hero_p {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
  .hero_img {
    width: auto;
    height: 30.625rem;
  }
  /* Promotions */
  .promotions {
    margin-top: 4rem;
  }
  .promotions-title {
    margin-bottom: 2.125rem;
  }
  .promotions-events {
    flex-direction: column;
  }
  .promotions-left {
    width: 100%;
  }
  .promotions-right {
    width: 100%;
    justify-content: space-around;
  }
  /* Overview */
  .productOverview {
    align-items: flex-start;
    margin-bottom: 2.5rem;
  }
  .overview-title {
    text-align: center;
    width: auto;
    font-size: 2.5rem;
  }
  .overview-content {
    flex-direction: column;
  }
  .overview-info {
    width: 100%;
  }
  .overview-image {
    width: 100%;
    margin-bottom: 2rem;
  }
  .overview-image-info {
    width: 100%;
    text-align: center;
  }
  .product-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .images-list {
    width: auto;
    margin-right: 3rem;
  }
  .size {
    padding: 0.5rem;
  }
  .btn-cart {
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
  }
  .price {
    white-space: nowrap;
  }
  .cart-container {
    gap: 1.5rem;
  }
  .cart-page {
    margin-top: 2rem;
    padding: 0;
  }
  .footer-info {
    margin-right: 5rem;
  }
}
